/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\ttitle=\"Membership billing run is in process in the background.\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\tThe system will notify you once{'\\u00A0'}completed.\n\t</Toast>\n")), "\n", React.createElement(_components.h1, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, "Usage", React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Toast notifications are used when:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The user does not need to be interrupted."), "\n", React.createElement(_components.li, null, "The user is being informed of an event, with the option to take action."), "\n", React.createElement(_components.li, null, "The results of an action are delayed."), "\n", React.createElement(_components.li, null, "The notification could appear anywhere in the application."), "\n"), "\n", React.createElement(_components.p, null, "All notifications share the non-interruptive function. ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/banner"
  }, "Banner")), " notifications are tied to a specific view and are usually generated immediately after a save. ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/announcement"
  }, "Announcement")), " notifications convey an app-wide event and not feedback on a workflow. ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/snackbar"
  }, "Snackbar")), " notifications are less-intrusive, concise messages that inform a user that an action has been completed."), "\n", React.createElement(_components.h1, {
    id: "status-variations",
    style: {
      position: "relative"
    }
  }, "Status Variations", React.createElement(_components.a, {
    href: "#status-variations",
    "aria-label": "status variations permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "info-default",
    style: {
      position: "relative"
    }
  }, "Info (Default)", React.createElement(_components.a, {
    href: "#info-default",
    "aria-label": "info default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Communicates operational information to the user. These are commonly used to denote the start of an operation, and usually includes follow up notifications. A one-off acknowledgement notification could also be a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/snackbar"
  }, "Snackbar")), ". If content in an area needs to be blocked with an operation, a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/mask"
  }, "loading mask")), " could be used over the Info Toast."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\ttitle=\"The batch is processing.\"\n\t\tonClose={() => {}}\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName={\"View Progress\"}\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\tYou will be notified once completed.\n\t</Toast>\n")), "\n", React.createElement(_components.h2, {
    id: "success",
    style: {
      position: "relative"
    }
  }, "Success", React.createElement(_components.a, {
    href: "#success",
    "aria-label": "success permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Communicates the success of an operation, e.g. a long-running process has finished. These are often paired with an info toast."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"success\"\n\t\ttitle=\"All Done!\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\t7 items were added.\n\t\tYou can view a history of all changes in the <Link primary>Sync Log</Link>.\n\t</Toast>\n")), "\n", React.createElement(_components.h2, {
    id: "criticalerror",
    style: {
      position: "relative"
    }
  }, "Critical/Error", React.createElement(_components.a, {
    href: "#criticalerror",
    "aria-label": "criticalerror permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Toast errors are when an operation was unable to be performed or has failed. Page-specific and form errors should utilize the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/banner"
  }, "Banner")), " notification. When applicable, users should be able to take action on an operation."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"critical\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"View Errors\"\n\t\ttitle=\"We were unable to process the bulk widget action.\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"critical\"\n\t\ttitle=\"Access to legacy reports is disabled.\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.h2, {
    id: "warning",
    style: {
      position: "relative"
    }
  }, "Warning", React.createElement(_components.a, {
    href: "#warning",
    "aria-label": "warning permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Warnings are distinct from errors and represent issues that do not need an immediate resolution, but still need to be addressed. Unintended consequences in an operation and fallback choices are common examples. Users in general should be able to take action on a warning."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"warning\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Configure Payroll\"\n\t\ttitle=\"Adjustment Completed\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\tThe adjustment will not affect the current payroll because it is already frozen.\n\t</Toast>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"warning\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"View Job Value\"\n\t\tonSecondaryActionClick={() => {}}\n\t\tsecondaryActionName=\"Undo Estimate\"\n\t\ttitle=\"Job value not found, an estimate has been applied\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "actions",
    style: {
      position: "relative"
    }
  }, "Actions", React.createElement(_components.a, {
    href: "#actions",
    "aria-label": "actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "0–2 actions can be added to each Toast. Action styling is predefined as one primary and one secondary action."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"critical\"\n\t\ttitle=\"You need to be logged in to continue using the system\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Login\"\n\t\tonSecondaryActionClick={() => {}}\n\t\tsecondaryActionName=\"Reset Password\"\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.h1, {
    id: "close-action",
    style: {
      position: "relative"
    }
  }, "Close Action", React.createElement(_components.a, {
    href: "#close-action",
    "aria-label": "close action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In general, toast should be dismissible. Important, persistent events are the general exception."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\ttitle=\"Bulk booking is in the process in the background.\"\n\t\tonClose={() => {}}\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "\t<Toast\n\t\tstatus=\"critical\"\n\t\ttitle=\"We were unable to bulk book the selections.\"\n\t\tportal={false}\n\t\tduration={0}\n\t/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "with-a-progress-bar",
    style: {
      position: "relative"
    }
  }, "With a Progress Bar", React.createElement(_components.a, {
    href: "#with-a-progress-bar",
    "aria-label": "with a progress bar permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When it is possible to calculate the time it takes to complete a task, a Toast can be paired with a ", React.createElement(_components.a, {
    href: "/components/progress-bar/"
  }, "Progress Bar"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Toast.Group portal={false}>\n\t<Toast\n\t\tstatus=\"info\"\n\t\ttitle=\"Import Adjustments\"\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\tIn progress\n\t\t<ProgressBar progress={30} small blue className=\"m-t-1\" />\n\t</Toast>\n\t<Toast\n\t\tstatus=\"success\"\n\t\ttitle=\"Successful Import\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"View Import\"\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\t<ProgressBar progress={100} small green className=\"m-t-3\" />\n\t</Toast>\n\t<Toast\n\t\tstatus=\"critical\"\n\t\ttitle=\"Error in Import Adjustment\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Widget Page\"\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\tThere was an error in processing the import.\n\t\t<ProgressBar progress={60} small red className=\"m-t-1\" />\n\t</Toast>\n</Toast.Group>\n")), "\n", React.createElement(_components.h1, {
    id: "duration",
    style: {
      position: "relative"
    }
  }, "Duration", React.createElement(_components.a, {
    href: "#duration",
    "aria-label": "duration permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "By default, Toasts expire after 8 seconds which is our recommended expiration duration. This can also be set to only expire on user action by removing the duration."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "grouped-toasts",
    style: {
      position: "relative"
    }
  }, "Grouped Toasts", React.createElement(_components.a, {
    href: "#grouped-toasts",
    "aria-label": "grouped toasts permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Multiple Toasts can be displayed on the screen. In general, newer toasts should appear at the top of the group. By default, only 5 toasts are shown at a time. Older messages are hidden from view for newer messages."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={{ index: 0, items: [] }}>\n\t{([state, setState]) => (\n\t\t<>\n\t\t\t<Toast.Group>\n\t\t\t\t{state.items.map(index => (\n\t\t\t\t\t<Toast\n\t\t\t\t\t\tkey={index}\n\t\t\t\t\t\tportal={false}\n\t\t\t\t\t\ttitle={`Message #${index}`}\n\t\t\t\t\t\tonClose={() => setState({\n\t\t\t\t\t\t\titems: state.items.filter(idx => idx !== index)\n\t\t\t\t\t\t})}\n\t\t\t\t\t\tonPrimaryActionClick={() => setState({\n\t\t\t\t\t\t\titems: state.items.filter(idx => idx !== index)\n\t\t\t\t\t\t})}\n\t\t\t\t\t\tprimaryActionName=\"Close\"\n\t\t\t\t\t/>\n\t\t\t\t))}\n\t\t\t</Toast.Group>\n\t\t\t<Button onClick={() => setState({\n\t\t\t\tindex: state.index + 1,\n\t\t\t\titems: [state.index].concat(state.items)\n\t\t\t})}>Demo Multiple Toasts</Button>\n\t\t</>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Titles should not stretch beyond two lines."), "\n", React.createElement(_components.li, null, "In a queue, priority is given to critical/error statuses, followed by order in which they are generated."), "\n", React.createElement(_components.li, null, "Don't use an action button to close the toast."), "\n", React.createElement(_components.li, null, "Don't use this pattern for handling page specific errors."), "\n", React.createElement(_components.li, null, "Avoid blaming the user for an operational error. Accept blame when we know it's our fault."), "\n", React.createElement(_components.li, null, "Avoid excessive use of the pattern. While these do not interrupt the user, they can be distracting."), "\n", React.createElement(_components.li, null, "Avoid technical jargon."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-components",
    style: {
      position: "relative"
    }
  }, "Related Components", React.createElement(_components.a, {
    href: "#related-components",
    "aria-label": "related components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To require a user interaction, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modal")), " component."), "\n", React.createElement(_components.li, null, "For critical information that requires a specific decision, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/dialog"
  }, "Dialog")), " component."), "\n"), "\n", React.createElement(_components.p, null, "For non-intrusive notifications:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Site-wide information about an event, use the  ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/announcement"
  }, "Announcement")), " notification."), "\n", React.createElement(_components.li, null, "Content specific to the page, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/banner"
  }, "Banner")), " notification."), "\n", React.createElement(_components.li, null, "Quick, successful information, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/snackbar/"
  }, "Snackbar")), " notification."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Toast } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
